import { includes, range } from "ramda";

/**
 * @description Convert an http url to https
 * @param url An http url
 * @returns An https url
 */
export const convertHttpUrlToHttps = (url: string) =>
  url ? url.replace(/^http:\/\//i, "https://") : "";

export enum HttpStatusClass {
  INFO_1XX = "INFO",
  SUCCESS_2XX = "SUCCESS",
  REDIRECT_3XX = "REDIRECT",
  CLIENT_ERROR_4XX = "CLIENT_ERROR",
  SERVER_ERROR_5XX = "SERVER_ERROR",
  NO_RESPONSE = "NO_RESPONSE",
}

export const HttpStatusGoodResponses = [
  HttpStatusClass.INFO_1XX,
  HttpStatusClass.SUCCESS_2XX,
  HttpStatusClass.REDIRECT_3XX,
];

export const HttpStatusBadResponses = [
  HttpStatusClass.CLIENT_ERROR_4XX,
  HttpStatusClass.SERVER_ERROR_5XX,
];

/**
 * @description Get the status class from a server response. Useful for data masking and short circuiting response logic.
 * @param status The numerical status returned in the response
 * @returns Value from enum HttpStatusClass
 */
export const getHttpStatusClass = (status: number) => {
  const info = range(100, 200);
  const success = range(200, 300);
  const redirect = range(300, 400);
  const clientError = range(400, 500);
  const serverError = range(500, 600);

  if (includes(status, info)) {
    return HttpStatusClass.INFO_1XX;
  } else if (includes(status, success)) {
    return HttpStatusClass.SUCCESS_2XX;
  } else if (includes(status, redirect)) {
    return HttpStatusClass.REDIRECT_3XX;
  } else if (includes(status, clientError)) {
    return HttpStatusClass.CLIENT_ERROR_4XX;
  } else if (includes(status, serverError)) {
    return HttpStatusClass.SERVER_ERROR_5XX;
  } else {
    return HttpStatusClass.NO_RESPONSE;
  }
};

/**
 * Loop and refetch imageUrl if it does not return a success status.
 *
 * @param imageUrl The URL to fetch on.
 * @param maxAttempts The max number of attempts to fetch before giving up.
 * @param pollingInterval  The number in ms to wait between fetch attempts.
 * @returns The imageUrl or empty string if fetching failed.
 */
export const fetchImageUrl = async (
  imageUrl: string,
  maxAttempts: number,
  pollingInterval: number
) => {
  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  let refetchCount = 0;

  while (refetchCount < maxAttempts) {
    let res: Response;

    if (refetchCount !== 0) await wait(pollingInterval);
    refetchCount++;

    try {
      res = await fetch(imageUrl);
      if (getHttpStatusClass(res.status) === HttpStatusClass.SUCCESS_2XX) {
        return res.url;
      }
    } catch (error) {
      continue;
    }
  }
  return "";
};
