import { MouseEvent } from "react";
import { IntlShape } from "react-intl";
import {
  Disposable,
  FetchPolicy,
  PreloadedQuery,
  UseMutationConfig,
  UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import { MutationParameters, OperationType, VariablesOf } from "relay-runtime";

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type AnyFunction = (...args: any[]) => any;

export type StringMap<T> = { [key: string]: T };

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type RecursivelyRequired<T> = {
  [P in keyof T]-?: T[P] extends (infer U)[]
    ? RecursivelyRequired<U>[]
    : T[P] extends object
    ? RecursivelyRequired<T[P]>
    : T[P];
};

/**
 * Sets specific fields as optional on a type
 */
export type OptionalFields<Type, Keys extends keyof Type> = Partial<
  Pick<Type, Keys>
> &
  Omit<Type, Keys>;

/**
 * Ensures a type has a value and is not undefined or null
 */
export type EnsureValue<Type> = Type extends undefined
  ? never
  : Type extends null
  ? never
  : Type;

/**
 * Ensures all fields exist on the object, making all fields required
 */
export type EnsureAllFields<Type> = EnsureFields<Type, keyof Type>;

/**
 * Ensures certain fields of a type exist and are not null or undefined,
 * all other fields become optional.
 */
export type EnsureFields<Type, Keys extends keyof Type> = Required<
  Pick<{ [K in keyof Type]-?: EnsureValue<Required<Type[K]>> }, Keys>
> &
  Omit<Partial<Type>, Keys>;

/**
 * Sets specific fields of a type to be Required (not null or undefined).
 */
export type RequireFields<Type, Keys extends keyof Type> = Omit<Type, Keys> &
  Required<{
    [Key in Keys]: EnsureValue<Type[Key]>;
  }>;

export enum SecureComEnv {
  Dev1 = "dev1",
  Dev2 = "dev2",
  Beta = "beta",
  Test = "test",
  Production = "production",
}

export type ReactMouseEventHandler = (
  event: MouseEvent<HTMLButtonElement>
) => void;

export type Mutable<A> = {
  -readonly [K in keyof A]: Mutable<A[K]>;
};

export type Readonly<A> = {
  readonly [K in keyof A]: Readonly<A[K]>;
};

export type MutableOrReadonly<A> = Mutable<A> | Readonly<A>;

export type Never<K extends keyof any> = {
  [P in K]?: never;
};

export type InferArrayType<Type extends any[]> = Type extends
  | ReadonlyArray<infer U>
  | (infer U)[]
  ? U
  : never;

export type ExtraKeys<A, B> = Exclude<keyof A, keyof B>;

export type NotNullOrUndefined<A> = A extends null | undefined ? never : A;

export type InferPromiseType<A extends Promise<any>> = A extends Promise<
  infer B
>
  ? B
  : never;

export function castAs<A>(arg: any): A {
  return arg as unknown as A;
}

export type ISODateString = string;

/** Shape of a message from react-intl */
export type IntlMessage = Record<string, string>;

/** Shape of `formatMessage` from `useIntl()`. */
export type IntlFormatMessage = IntlShape["formatMessage"];

/** Shape of use notification hooks, such as `useWarningNotification()`. */
export type UseNotificationType = ({
  id,
  ...rest
}: {
  [x: string]: any;
  id: any;
}) => void;

/**
 * @description Create a type from the values of a type. Useful for typing key value pairs.
 * @example fieldName: keyof FormStateProps, value: ValueOf<FormStateProps>
 */
export type ValueOf<T> = T[keyof T];

/** Standard type of value label pairs used in dropdowns and `react-select` components. */
export type ValueLabelPair = { value: string; label: string };

/** For manually typing query options for Relay */
export type QueryOptionsProps = {
  fetchKey: any;
  fetchPolicy: FetchPolicy;
};

/** 
 * @description Create a mutation function type from a given Relay mutation type. Useful for typing context and reducer values.
 * @example type FormContextTypes = {
      createVideoAction:MutationTypeOf<VideoActionFormCreateMutation>;
 * }
 */
export type MutationTypeOf<T extends MutationParameters> = (
  config: UseMutationConfig<T>
) => Disposable;

/** 
 * @description Create a type for a preloaded query reference from a given Relay query type.
 * @example type Props = {
      queryReference: PreloadedTypeOf<VideoSettingsQuery>;
 * }
 */
export type PreloadedTypeOf<T extends OperationType> = PreloadedQuery<
  T,
  Record<string, unknown>
>;

/** 
 * @description Create a type for a query loader from a given Relay query type.
 * @example type Props = {
        loadUsersPageQuery: QueryLoaderTypeOf<UserFormQuery>
 * }
 */
export type QueryLoaderTypeOf<T extends OperationType> = (
  variables: VariablesOf<T>,
  options?: UseQueryLoaderLoadQueryOptions | undefined
) => void;

/**
 * @description Type match props from react-router
 * @example type Props = {
        match: MatchProps<{databaseCameraId: string}>
 *   }
 * ...
 * const databaseCameraId = match?.params.databaseCameraId;
 */
export type MatchProps<T extends { [K in keyof T]?: string | undefined }> = {
  params: T;
  url: string;
  isExact: boolean;
  path: string;
};

/** Type for event.target.value */
export type GenericEventTargetValue = {
  target?: {
    value?: string;
  };
};
