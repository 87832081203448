import { always } from "ramda";
import { SecureComEnv } from "./types";

function toStagingEnv(env: SecureComEnv) {
  switch (env) {
    case SecureComEnv.Dev1:
    case SecureComEnv.Dev2:
      return "dev";
    default:
      return "prod";
  }
}

// ---------------------------
// Clients
// ---------------------------

export const getVirtualKeypadUrl = (
  env: SecureComEnv,
  stagingPrefix: string = ""
) => {
  if (stagingPrefix) {
    return `https://${stagingPrefix}-${toStagingEnv(
      env
    )}.staging.virtualkeypad.com`;
  }

  switch (env) {
    case SecureComEnv.Dev1:
      return "https://wwwd.virtualkeypad.com";
    case SecureComEnv.Dev2:
      return "https://wwwd2.virtualkeypad.com";
    default:
      return "https://www.virtualkeypad.com";
  }
};

export const getDealerAdminUrl = (
  env: SecureComEnv,
  stagingPrefix: string = ""
) => {
  if (stagingPrefix) {
    return `https://${stagingPrefix}-${toStagingEnv(
      env
    )}.staging.dealer.securecomsoftware.com`;
  }

  switch (env) {
    case SecureComEnv.Dev1:
      return "https://dealerd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://dealerd2.securecomwireless.com";
    default:
      return "https://dealer.securecomwireless.com";
  }
};

// ---------------------------
// APIs
// ---------------------------

export const getTunnelUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://tunneld.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://tunneld2.securecomwireless.com";
    default:
      return "https://tunnel.securecomwireless.com";
  }
};

export const getEventsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://eventsd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://eventsd2.securecomwireless.com";
    default:
      return "https://events.securecomwireless.com";
  }
};

export const getHlsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://hlsd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://hlsd2.securecomwireless.com";
    default:
      return "https://hls.securecomwireless.com";
  }
};

export const getJobsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://jobsd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://jobsd2.securecomwireless.com";
    default:
      return "https://jobs.securecomwireless.com";
  }
};

export const getOdataUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://odd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://odd2.securecomwireless.com";
    default:
      return "https://od.securecomwireless.com";
  }
};

export const getOdataReportsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://odd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://odd2.securecomwireless.com";
    default:
      return "https://odevt.securecomwireless.com";
  }
};

export const getOdataEventHistoriesUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://odd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://odd2.securecomwireless.com";
    default:
      return "https://odevthst.securecomwireless.com";
  }
};

export const getOdataConnectionHistoriesUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://odd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://odd2.securecomwireless.com";
    default:
      return "https://od.securecomwireless.com";
  }
};
export const getPayServerUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://paysrvd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://paysrvd2.securecomwireless.com";
    default:
      return "https://paysrv.securecomwireless.com";
  }
};

export const getThirdPartyVideoUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://vsapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://vsapid2.securecomwireless.com";
    default:
      return "https://vsapi.securecomwireless.com";
  }
};

/**
 * Sets the URL of the Camect-API
 *
 * Is used by GraphQL server to access Camect-API
 *
 * @param env
 * @returns string
 */
export const getCamectUrl = (env: SecureComEnv): string => {
  const camectUrl: string | undefined = process.env.REACT_APP_CAMECT_URL;
  if (camectUrl !== undefined) {
    if (!camectUrl.startsWith("http")) {
      return "https://" + camectUrl;
    }
    return camectUrl;
  } else {
    switch (env) {
      case SecureComEnv.Dev1:
        return "https://camectd.securecomwireless.com";
      case SecureComEnv.Dev2:
        return "https://camectd2.securecomwireless.com";
      default:
        return "https://camect.securecomwireless.com";
    }
  }
};

export const getVidproxUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://vidproxd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://vidproxd2.securecomwireless.com";
    default:
      return "https://vidprox.securecomwireless.com";
  }
};

export const getVkUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://dmpapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://dmpapid2.securecomwireless.com";
    default:
      return "https://dmpapi.securecomwireless.com";
  }
};

export const getVkOldUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://apid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://apid2.securecomwireless.com";
    default:
      return "https://api.securecomwireless.com";
  }
};

export const getDmpUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://dmpapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://dmpapid2.securecomwireless.com";
    default:
      return "https://dmpapi.securecomwireless.com";
  }
};

export const getFilesUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://filesd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://filesd2.securecomwireless.com";
    default:
      return "https://files.securecomwireless.com";
  }
};

/**
 * URL for ScheduledService (Deferred Billing)
 * @param {SecureComEnv} env - The SecureCom env
 */
export const getSSUrl = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
      return "https://billapid.securecomwireless.com";
    case "dev2":
      return "https://billapid2.securecomwireless.com";
    default:
      return "https://billapi.securecomwireless.com";
  }
};

export const getCompanyStoreUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://alarmstored.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://alarmstored2.securecomwireless.com";
    default:
      return "https://alarmstore.securecomwireless.com";
  }
};

export const getPaymentServiceUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://paysrvd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://paysrvd2.securecomwireless.com";
    default:
      return "https://paysrv.securecomwireless.com";
  }
};

export const getImageApiUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://logosd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://logosd.securecomwireless.com";
    default:
      return "https://logos.securecomwireless.com";
  }
};

export const getIntegrationUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://disd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://disd2.securecomwireless.com";
    default:
      return "https://dis.securecomwireless.com";
  }
};

export const getCoverageMapUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://covmapsd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://covmapsd2.securecomwireless.com";
    default:
      return "https://covmaps.securecomwireless.com";
  }
};

export const getBillingUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://billapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://billapid2.securecomwireless.com";
    default:
      return "https://billapi.securecomwireless.com";
  }
};

export const getPaymentsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://payapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://payapid2.securecomwireless.com";
    default:
      return "https://payapi.securecomwireless.com";
  }
};

export const getDealerInvoiceUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://dealinvd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://dealinvd2.securecomwireless.com";
    default:
      return "https://dealinv.securecomwireless.com";
  }
};

export const getOrbiPayHostedFormsUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
    case SecureComEnv.Dev2:
      return "https://sbjsco.billerpayments.com/app/opco/v3/scripts/checkoutofsc.js";
    default:
      return "https://jsco.billerpayments.com/app/opco/v3/scripts/checkoutofsc.js";
  }
};

export const getJobStatusUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://jobstatd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://jobstatd2.securecomwireless.com";
    default:
      return "https://jobstat.securecomwireless.com";
  }
};

export const getPulseUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://pulsed.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://pulsed2.securecomwireless.com";
    default:
      return "https://pulse.securecomwireless.com";
  }
};

export const getDealerUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "https://dealerapid.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "https://dealerapid2.securecomwireless.com";
    default:
      return "https://dealerapi.securecomwireless.com";
  }
};

/**
 * URL for SIM activations as well as Rate and Text Plans.
 * This was a 1 for 1 swap that was added to remove the
 * old WS route without changing the code in every place it
 * used */
export const getSimsUrl = getDealerUrl;

/**
 * Returns URL of the GraphQL server
 *
 * Is used by Dealer Admin to communicate to Camect-API, etc
 *
 * @param env
 * @returns string
 */
export const getGQLUrl = (env: SecureComEnv): string => {
  const graphQLUrl: string | undefined = process.env.REACT_APP_GQL_URL;
  if (graphQLUrl !== undefined) {
    if (!graphQLUrl.startsWith("http")) {
      return "https://" + graphQLUrl;
    }
    return graphQLUrl;
  } else {
    switch (env) {
      case SecureComEnv.Dev1:
        return "https://gqd.securecomwireless.com";
      case SecureComEnv.Dev2:
        return "https://gqd2.securecomwireless.com";
      case SecureComEnv.Beta:
        return "https://gqbeta.securecomwireless.com";
      case SecureComEnv.Test:
        return "https://gqtest.securecomwireless.com";
      default:
        return "https://gq.securecomwireless.com";
    }
  }
};

export const getGQLWSUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "wss://gqd.securecomwireless.com/subscriptions";
    case SecureComEnv.Dev2:
      return "wss://gqd2.securecomwireless.com/subscriptions";
    case SecureComEnv.Beta:
      return "wss://gqbeta.securecomwireless.com/subscriptions";
    case SecureComEnv.Test:
      return "wss://gqtest.securecomwireless.com/subscriptions";
    default:
      return "wss://gq.securecomwireless.com/subscriptions";
  }
};

export const getRabbitMQUrl = (env: SecureComEnv) => {
  switch (env) {
    case SecureComEnv.Dev1:
      return "rabbitmqd.securecomwireless.com";
    case SecureComEnv.Dev2:
      return "rabbitmqd2.securecomwireless.com";
    default:
      return "rabbitmq.securecomwireless.com";
  }
};

export const getWorkHorseUrl = (env: SecureComEnv) => {
  return "https://theintegrationserver.com";
};

export const getIPResolverUrl = always("https://ipapi.co/json");

export const getApUiUrl = (env: SecureComEnv) => {
  const apUiUrl: string | undefined = process.env.REACT_APP_AP_UI_URL;

  if (apUiUrl !== undefined) {
    if (!apUiUrl.startsWith("http")) {
      return "https://" + apUiUrl;
    }
    return apUiUrl;
  } else {
    switch (env) {
      case SecureComEnv.Dev1:
        if (process.env.REACT_APP_CLIENT_STAGING_BRANCH) {
          return `https://ap-ui-${process.env.REACT_APP_CLIENT_STAGING_BRANCH}-dev.staging.dealer.securecomsoftware.com`;
        } else {
          return "https://apd.staging.dealer.securecomsoftware.com";
        }
      default:
        if (process.env.REACT_APP_CLIENT_STAGING_BRANCH) {
          return `https://ap-ui-${process.env.REACT_APP_CLIENT_STAGING_BRANCH}-prod.staging.dealer.securecomsoftware.com`;
        } else {
          return "https://ap.securecomwireless.com";
        }
    }
  }
};

export const getBarCodeUrl = (env: SecureComEnv) => {
  const barCodeUrl: string | undefined = process.env.REACT_APP_BARCODE_URL;
  if (barCodeUrl !== undefined) {
    if (!barCodeUrl.startsWith("http")) {
      return "https://" + barCodeUrl;
    }
    return barCodeUrl;
  } else {
    switch (env) {
      case SecureComEnv.Dev1:
        return "https://bcs.staging.dealer.securecomsoftware.com";
      default:
        return "https://bcs.securecomwireless.com";
    }
  }
};

export const getSecureComUrls = ({
  env,
  stagingPrefixForClients = "",
}: SecureComUrlCreatorInput) => ({
  /** https://billapid.securecomwireless.com */
  billing: getBillingUrl(env),

  /** https://alarmstored.securecomwireless.com */
  companyStore: getCompanyStoreUrl(env),

  /** https://covmapsd.securecomwireless.com */
  coverageMap: getCoverageMapUrl(env),

  /** https://dealerapid.securecomwireless.com */
  dealer: getDealerUrl(env),

  /** https://dealerd.securecomwireless.com */
  dealerAdmin: getDealerAdminUrl(env, stagingPrefixForClients),

  /** https://dealinvd.securecomwireless.com */
  dealerInvoice: getDealerInvoiceUrl(env),

  /** https://dmpapid.securecomwireless.com */
  dmp: getDmpUrl(env),

  /** https://eventsd.securecomwireless.com */
  eventsUrl: getEventsUrl(env),

  /** https://gqd.securecomwireless.com */
  gql: getGQLUrl(env),

  /** wss://gqd.securecomwireless.com/subscriptions */
  gqlws: getGQLWSUrl(env),

  /** https://hlsd.securecomwireless.com */
  hls: getHlsUrl(env),

  /** https://logosd.securecomwireless.com */
  images: getImageApiUrl(env),

  /** https://disd.securecomwireless.com */
  integration: getIntegrationUrl(env),

  /** https://ipapi.co/json */
  ipResolver: getIPResolverUrl(),

  /** https://jobsd.securecomwireless.com */
  jobs: getJobsUrl(env),

  /** https://jobstatd.securecomwireless.com */
  jobStatus: getJobStatusUrl(env),

  /** https://odd.securecomwireless.com */
  odata: getOdataUrl(env),

  /** https://odd.securecomwireless.com */
  odataReports: getOdataReportsUrl(env),

  /** https://odd.securecomwireless.com */
  odataHistory: getOdataEventHistoriesUrl(env),

  /** https://odd.securecomwireless.com */
  odataConnectionHistory: getOdataConnectionHistoriesUrl(env),

  /** https://sbjsco.billerpayments.com/app/opco/v3/scripts/checkoutofsc.js */
  orbiPayHostedForms: getOrbiPayHostedFormsUrl(env),

  /** https://payapid.securecomwireless.com */
  payments: getPaymentsUrl(env),

  /** https://paysrvd.securecomwireless.com */
  paymentService: getPaymentServiceUrl(env),

  /** https://paysrvd.securecomwireless.com */
  payServer: getPayServerUrl(env),

  /** https://pulsed.securecomwireless.com */
  pulse: getPulseUrl(env),

  /** rabbitmqd.securecomwireless.com */
  rabbit: getRabbitMQUrl(env),

  /** https://wsdev.securecomwireless.com */
  sims: getSimsUrl(env),

  /** https://vsapid.securecomwireless.com */
  scheduledServiceUrl: getSSUrl(env),

  /** https://vsapid.securecomwireless.com */
  thirdPartyVideo: getThirdPartyVideoUrl(env),

  /** https://camectd.securecomwireless.com */
  camect: getCamectUrl(env),

  /** https://vidproxd.securecomwireless.com */
  vidprox: getVidproxUrl(env),

  /** https://tunneld.securecomwireless.com */
  tunnelUrl: getTunnelUrl(env),

  /** https://wwwd.virtualkeypad.com */
  virtualKeypad: getVirtualKeypadUrl(env, stagingPrefixForClients),

  /** https://dmpapid.securecomwireless.com */
  vk: getVkUrl(env),

  /** https://apid.securecomwireless.com */
  vkOld: getVkOldUrl(env),

  /** https://theintegrationserver.com */
  workhorse: getWorkHorseUrl(env),

  /** https://filesd.securecomwireless.com */
  files: getFilesUrl(env),

  /** https://apd.staging.dealer.securecomsoftware.com */
  apUiUrl: getApUiUrl(env),

  /** https://bcs.staging.dealer.securecomsoftware.com */
  barCodeUrl: getBarCodeUrl(env),
});

export type SecureComUrlCreatorInput = {
  env: SecureComEnv;
  stagingPrefixForClients?: string;
};

export type UrlCreator = (path: string) => string;
export type UrlCreators = {
  [K in keyof ReturnType<typeof getSecureComUrls>]: UrlCreator;
};

export const getSecureComUrlCreators = (input: SecureComUrlCreatorInput) => {
  const urls = getSecureComUrls(input);

  return Object.entries(urls).reduce(
    (acc, [name, url]) => ({
      ...acc,
      [name]: (path: string) => new URL(path, url).toString(),
    }),
    {} as UrlCreators
  );
};
