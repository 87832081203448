/**
 * These utilities are ports from the GraphQL layer.
 * Currently the clients are still transitioning to use
 * the GraphQL layer. During this transition period these
 * help provide ease of transition. However, these should
 * not be used in any new code or changes if possible.
 */

import { constant } from "fp-ts/lib/function";
import { anyPass, both, either } from "ramda";

/*
 * Control System Utils
 */

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const hardwareModel = (system: any) => system.panels[0].hardware_model;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
const hasHardwareModel = (target: string) => (system: any) =>
  hardwareModel(system) === target;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXt30 = hasHardwareModel("XT30");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXt30L = hasHardwareModel("XT30L");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXt50 = hasHardwareModel("XT50");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXt50L = hasHardwareModel("XT50L");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXtl = hasHardwareModel("XTL");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXtlN = hasHardwareModel("XTLN");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isXtlW = hasHardwareModel("XTLW");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXtlPlus = hasHardwareModel("XTLP");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXr150 = hasHardwareModel("XR150");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXr350 = hasHardwareModel("XR350");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXr550 = hasHardwareModel("XR550");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXf500 = hasHardwareModel("XF6_500");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXf100 = hasHardwareModel("XF6_100");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isCellComSl = hasHardwareModel("CellComSL");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isIComSl = hasHardwareModel("iComSL");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isDualCom = hasHardwareModel("DualCom");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isMiniCellCom = hasHardwareModel("MiniCellCom");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isCellComEx = hasHardwareModel("CellComEX");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isVideoOnly = hasHardwareModel("Video Only");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isX1 = hasHardwareModel("X001");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXr = (system: any) =>
  [isXr150, isXr350, isXr550].some((fn) => fn(system));

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXf = (system: any) =>
  [isXf500, isXf100].some((fn) => fn(system));

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isXt = (system: any) =>
  [
    isXt30,
    isXt30L,
    isXt50,
    isXt50L,
    isXtlPlus,
    isXtl,
    isXtlN,
    isXtlW,
    isCellComSl,
    isIComSl,
    isDualCom,
    isMiniCellCom,
    isCellComEx,
  ].some((fn) => fn(system));

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const controlSystemArmingType = (system: any) =>
  system.panels[0].arming_system;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ const hasArmingType = (armingType: string) => (system: any) =>
  controlSystemArmingType(system) === armingType;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isAPSystem = hasArmingType("AP");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isHASystem = hasArmingType("HA");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isHSASystem = hasArmingType("HSA");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isHSAGSystem = either(
  hasArmingType("HSAWG"),
  hasArmingType("HSAG")
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isAreaSystem = hasArmingType("AREA");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const isTakeoverPanel: (controlSystem: any) => boolean = anyPass([
  isCellComSl,
  isIComSl,
  isDualCom,
  isMiniCellCom,
  isCellComEx,
]);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const hasDoorAccessGrantedSempros = (system: any) =>
  !!system.panels[0].devices?.length &&
  realTimeEventsEnabled(system) &&
  system.services_managers[0].door_access_level === "all";

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const hasDoorAccessDeniedSempros = (system: any) =>
  !!system.panels[0].devices?.length &&
  realTimeEventsEnabled(system) &&
  (system.services_managers[0].door_access_level === "all" ||
    system.services_managers[0].door_access_level === "denials_only");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const requiresUserCode = (system: any) =>
  isXf(system) ||
  system.panels[0].software_version >= (isXt(system) ? "116" : "103");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const wifiSetupEnabled = (system: any) =>
  system.panels[0].software_version >= "112";

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const hasLegacyTempUser = (system: any) =>
  isXr(system) && system.panels[0].software_version < "191";

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const supportsSempro = (system: any) =>
  isXf(system)
    ? true
    : isXr(system)
    ? system.panels[0].software_version >= "109"
    : system.panels[0].software_version >= "122";

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const supportsEditingEcpMasterUser = (system: any) =>
  system.panels[0].software_version >= "193" ||
  (system.panels[0].software_version === "192" &&
    system.panels[0].software_date === "10/04/19");

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const supportsActiveUserCodes = (system: any) =>
  isXf(system) || system.panels[0].software_version >= "172";

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const supportsEditing734Doors = (system: any) =>
  (isXr(system) &&
    system.panels[0].software_version >= "183" &&
    !!system.panels[0].devices.find(
      (device: { prg_734: string }) => device.prg_734 === "Y"
    )) ||
  isX1(system);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const videoEnabled = (system: any) =>
  system.services_managers[0].premium_video_enabled ||
  system.services_managers[0].standard_hikvision_nvr_enabled ||
  system.services_managers[0].open_eye_enabled ||
  system.services_managers[0].eagle_eye_enabled;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */ export const trafficCountEnabled = (system: any) =>
  system.services_managers[0].traffic_count_enabled;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const sensorActivityEnabled = (system: any) =>
  system.services_managers[0].sensor_activity_enabled;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const realTimeEventsEnabled = (system: any) =>
  system.services_managers[0].real_time_events_enabled;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const thirdPartyVideoEnabled = (system: any) =>
  system.services_managers[0].open_eye_enabled ||
  system.services_managers[0].eagle_eye_enabled ||
  system.services_managers[0].hikvision_doorbell_enabled;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const maxSchedulesPerDoor = (system: any) =>
  system.panels[0].software_version >= "191" ? 16 : 8;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const minimumVersion = (version: number) => (system: any) =>
  Number(system.panels[0].software_version) >= version;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const maxProfiles = constant(99);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsInstantArming: (controlSystem: any) => boolean = both(
  either(isXr, isXt),
  minimumVersion(171)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsTwilightScheduling: (controlSystem: any) => boolean = both(
  either(isXr, isXt),
  minimumVersion(171)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsSendCodesToLocks: (controlSystem: any) => boolean = both(
  either(isXr, isXt),
  minimumVersion(171)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const sendCodesToLocksUsers = {
  min: 2,
  max: 20,
};

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsZwaveOptimize: (controlSystem: any) => boolean = either(
  both(isXr, minimumVersion(182)),
  both(isXt, minimumVersion(125))
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const isSingleAreaSystem: (
  controlSystem: any
) => boolean = isMiniCellCom;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsEnhancedApp: (
  controlSystem: any
) => boolean = supportsSempro;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsUserCodeProgramming: (
  controlSystem: any
) => boolean = anyPass([
  isXf,
  both(isXr, minimumVersion(105)),
  both(isXt, minimumVersion(117)),
]);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsScheduleProgramming: (
  controlSystem: any
) => boolean = either(
  both(isXr, minimumVersion(106)),
  both(isXt, minimumVersion(119))
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsProfileProgramming: (controlSystem: any) => boolean = both(
  isXr,
  minimumVersion(107)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsAlarmCancelVerify: (
  controlSystem: any
) => boolean = either(
  both(isXr, minimumVersion(106)),
  both(isXt, minimumVersion(119))
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const userCodeRequired: (controlSystem: any) => boolean = anyPass([
  isXf,
  both(isXr, minimumVersion(103)),
  both(isXt, minimumVersion(116)),
]);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsPushNotifications: (
  controlSystem: any
) => boolean = anyPass([
  isXf,
  both(isXr, minimumVersion(103)),
  both(isXt, minimumVersion(116)),
]);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsInactiveUser: (controlSystem: any) => boolean = both(
  isXr,
  minimumVersion(172)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsLockdownOverride: (controlSystem: any) => boolean = both(
  isXr,
  minimumVersion(181)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsDualAuthority: (controlSystem: any) => boolean = both(
  isXr,
  minimumVersion(182)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsZoneBypass: (controlSystem: any) => boolean = either(
  both(isXr, minimumVersion(103)),
  both(isXt, minimumVersion(116))
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsUserActions: (controlSystem: any) => boolean = both(
  either(isXt, isXr),
  minimumVersion(182)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsRemotePanic: (controlSystem: any) => boolean = both(
  either(isXt, isXr),
  minimumVersion(193)
);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const supportsCustomActionMessage: (
  controlSystem: any
) => boolean = both(either(isXt, isXr), minimumVersion(193));

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const maxTimeSchedules = constant(99);

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const deriveLegacyPanelId = ({ account_prefix, account_number }: any) =>
  `${account_prefix}-${account_number}`;

/**
 * Prefer using the GraphQL layer instead.
 * @deprecated
 */
export const legacyPanelId = (system: any) =>
  deriveLegacyPanelId(system.panels[0]);
